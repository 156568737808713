.rt-th {
  background: #f4f3f8 !important;
  /* border: none!important; */
  color: #575962;
  font-size: 1rem;
  font-weight: 600;
  padding: 16px 10px !important;
}

.ReactTable .rt-thead.-header {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rt-td {
  vertical-align: middle;
  padding: 20px 10px 10px !important;
  font-size: 1.2rem;
  text-align: center;
}

.ReactTable.-striped .rt-tr.-odd {
  background: #fff;
}
.ReactTable.-striped .rt-tr.-even {
  background: #faf9fc;
}

.rt-table > .rt-thead {
  order: -2;
}

.rt-table > .rt-tbody {
  order: -1;
}

.rt-table > .rt-tfoot {
  order: -2;
}

.rt-table > .rt-tfoot > .rt-tr {
  border-bottom: 3px solid rgb(0 0 0 / 15%);
}
